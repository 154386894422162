$primary: #484848;
$secondary: #D7C6B3;
$success: #28a745;
$warning: #ffc107;
$danger: #dc3545;

:root {
    //--success-lighter-1: #{lighten($success, 50%)};
    //--warning-lighter-1: #{lighten($warning, 25%)};
    //--danger-lighter-1: #{lighten($danger, 25%)};
}

$enable-rounded: true;
$enable-gradients: false;
$enable-shadows: true;
$svg-color: grey;

@import "./node_modules/bootstrap/scss/bootstrap.scss";

.cursor-pointer {
    cursor: pointer;
}

.product-page-below-mobile-menu {
    flex-direction: column;
    @media screen and (min-width: 1201px) {
        height: 100%;
        flex-grow: 1;
        flex-direction: row;
    }
}

.product-customize-wrapper {
    flex: 0 0 24vw;
    max-width: 500px;
    background-color: rgba(68, 68, 68, 1);
    height: 100%;

    @media screen and (min-width: 1201px) {
        overflow-y: auto;
    }

    .product-customize-form {
        margin-top: 40px;

        .product-field {

            padding: 0 1rem 1rem 1rem;

            span {
                display: block;
                color: white;
                margin-bottom: 0.5rem;
            }

            a {
                color: white;
                padding-right: 0;
                padding-top: 0.375rem;
                padding-left: 0.5rem;

            }

        }

    }
}

.lock-drilling-switch > .switch-label {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    margin-bottom: .5rem;

    span.label {
        margin-bottom: 0;
    }

    @media screen and (max-width: 1645px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        span.label {
            margin-bottom: .5rem;
        }

        .btn-group-toggle {
            width: 100%;
        }
    }
}

.product-sketch-wrapper {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.product-sketch {

    height: 100%;
    width: 100%;

    .door-outline {
        stroke: black;
        stroke-width: 5;
    }

    .measurement {
        stroke: $svg-color;
        stroke-width: 3;
    }


    text {
        font-size: 30px;
        text-anchor: middle;
    }
}

.product-info-text-wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    flex: 0 0 24vw;
    max-width: 500px;
    background-color: #f3f3f3;

    img {
        max-width: 100%;
    }
}

@media screen and (max-width: 1200px) {
    .product-info-text-wrapper {
        overflow-y: visible;
        max-width: none;

        img {
            max-width: none;
            max-height: 50vh;
        }
    }
}

.no-product-found {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: auto;

    p {
        font-weight: bold;
        align-self: center;
    }
}


// Mobile
@media screen and (max-width: 1200px) {
    .only-mobile {
        display: block;
    }
    .only-desktop {
        display: none !important;
    }

    .product-customize-wrapper {
        max-width: 100%;
        width: 100%;
        flex: 1;

        .product-customize-form {
            max-width: 300px;
        }
    }

    .mobile-header {
        .toggle-screen-button {
            font-weight: bold;
            color: white;
            padding: 8px;
            margin: 8px 16px;
            border: 1px solid white;
            border-radius: 4px;
            text-transform: uppercase;

            .button-icon {
                line-height: 0;
                font-size: 2em;
            }
        }
    }
}

@media screen and (min-width: 1201px) {
    .only-mobile {
        display: none !important;
    }

    .only-desktop {
        display: block;
    }
}

//Overrides:

.product-table-container {
    .MuiPaper-root > div:nth-child(2) {
        > div > div > div {
            // Note: Must be changed when page layout changes (header/footer)
            // Could break when library update alters inner markup
            height: calc(100vh - 64px - 64px) !important;
        }

        //Manual overwrite since not possible to change via props
        .MuiTableSortLabel-active {
            color: white !important;

            .MuiTableSortLabel-icon {
                color: white !important;
            }

        }
    }
}

// to fix mobile view
.svg {
    max-height: 75vh;
    @media screen and (min-width: 1201px) {
        max-height: 100vh;
    }
}

.pin-login {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    padding: 20px;
    margin: auto;
    background-color: #444444;

    h4 {
        margin: auto;
    }
}

.pin-input {
    margin: 15px 0 15px 15px;
}

.pin-form {
    display: flex;
    align-items: center;

    span {
        display: block;
        margin-bottom: 0.5rem;
    }
}

.footer {
    display: flex;
    flex-direction: column;
    padding: 20px;

    a {
        margin: 5px 5px 5px 10px;
    }
}
