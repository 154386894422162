.door-outline {
    stroke: black;
    stroke-width: 5;
}

.measurement {
    stroke: grey;
    stroke-width: 3;
}

text {
    font-size: 30px;
    text-anchor: middle;
}
