@import "src/styles/colors";

button.btn {
    &.btn-primary {
        background-color: $primary-orange;
        color: darken($primary-grey, 20%);
        border-color: transparent;

        & > svg {
            font-size: 1.3em;
            translate: 0 2px;
            padding-right: 0.4rem;
        }
    }

    &.btn-secondary {
        background-color: lighten($primary-grey, 20%);
        color: white;
        border-color: transparent;
    }
}

.order-modal {
    max-width: 700px;
}
