@import "src/styles/colors";

// Tab customization
a.link-tab {
    color: white;
    text-decoration: none;

    & > .MuiTab-wrapper {
        font-size: 1.1em;

        & > svg {
            font-size: 1.6em;
        }
    }
}

// Selected tab
div.MuiTabs-fixed > span[class*="PrivateTabIndicator-root-"][class*="PrivateTabIndicator-colorSecondary-"] {
    background-color: $primary-orange;
    height: 4px;
}
