.selectable > div {
    & > div {
        &:first-child {
            color: white;
        }
        color: #c7c7c7;
        cursor: pointer;
        transition: 0.4s;
    }
    &:hover > div {
        color: #FFBE5D;
    }
}
