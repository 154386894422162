.base-layout-wrapper {
    flex-direction: column;

    @media screen and (min-width: 1201px) {
        flex-grow: 1;
        flex-direction: row;

        height: 100%;
    }
}

.left-sidebar-wrapper {
    flex: 0 0 24vw;

    height: 100%;
    max-width: 500px;
    min-width: 400px;

    background-color: rgba(68, 68, 68, 1);

    @media screen and (max-width: 1200px) {
        flex: 1;
        width: 100%;
        max-width: 100%;
    }
    @media screen and (min-width: 1201px) {
        overflow-y: auto;
    }
}

.right-sidebar-wrapper {
    display: flex;
    flex-direction: column;
    flex: 0 0 24vw;

    height: 100%;
    max-width: 500px;
    overflow-y: auto;

    background-color: #f3f3f3;

    img {
        max-width: 100%;
    }

    @media screen and (max-width: 1200px) {
        max-width: none;
        overflow-y: visible;

        img {
            max-width: none;
            max-height: 50vh;
        }
    }
}
