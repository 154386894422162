.product-customize-form {
    margin-top: 40px;

    .product-field {
        padding: 0 1rem 1rem 1rem;

        span {
            display: block;
            color: white;
            margin-bottom: 0.5rem;
        }

        a {
            color: white;
            padding-right: 0;
            padding-top: 0.375rem;
            padding-left: 0.5rem;
        }
    }

    @media screen and (max-width: 1200px) {
        max-width: 400px;
    }
}
